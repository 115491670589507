import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { HttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { isServer } from 'solid-js/web';
import { serverOnly$ } from 'vite-env-only/macros';

import { AUTH_TOKEN_COOKIE } from '~/utils/cookies';

if (process.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const uri = `${import.meta.env.VITE_API_ENDPOINT}/graphql`;
const httpLink = new HttpLink({ uri });

const token = await (async (): Promise<string> => {
  if (!import.meta.env.SSR && !isServer) {
    return Cookies.get(AUTH_TOKEN_COOKIE) ?? '';
  }

  const response = await fetch(uri, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'apollo-require-preflight': 'true',
    },
    body: JSON.stringify({
      query: `#graphql
        mutation Login($username: String!, $password: String!) {
          login(username: $username, password: $password) {
            token
          }
        }
      `,
      variables: serverOnly$({
        username: '\u007F',
        password: import.meta.env.VITE_APP_INTERNAL_KEY,
      }),
    }),
  });
  const payload = await response.json();
  return payload.data.login.token;
})();

const authLink = setContext((_, { headers }) => ({
  headers: { ...headers, authorization: token ? `Bearer ${token}` : '' },
}));

export const client = new ApolloClient({
  // eslint-disable-next-line unicorn/prefer-spread
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      UserProfile: {
        keyFields: ['slug'],
      },
    },
  }),
});
