import { gql } from '@apollo/client/core';
import { type onStatelessParameters } from '@hocuspocus/provider';
import { A, cache, createAsync, useParams, type Params } from '@solidjs/router';
import {
  batch,
  createEffect,
  createSignal,
  ErrorBoundary,
  For,
  mergeProps,
  onCleanup,
  onMount,
  Show,
  untrack,
} from 'solid-js';
import { useCookie } from 'solidjs-hooks';

import styles from '~/components/Post.module.scss';
import { type Provider } from '~/components/post-editing/build-collab';
import NotFound from '~/routes/[...404]';
import { clientLazy } from '~/utils/clientLazy';
import { AUTH_TOKEN_COOKIE } from '~/utils/cookies';
import { fragmentToJsx } from '~/utils/fragmentToJsx';
import { client } from '~/utils/graphql';
import { renderFragment } from '~/utils/renderFragment';
import Title from './Title';

const Checkbox = clientLazy(() => import('~/components/post-editing/Checkbox'));
const DatePicker = clientLazy(
  () => import('~/components/post-editing/DatePicker'),
);
const Editor = clientLazy(() => import('~/components/post-editing/Editor'));
const Image = clientLazy(() => import('~/components/post-editing/Image'));
const Select = clientLazy(() => import('~/components/post-editing/Select'));
const Tags = clientLazy(() => import('~/components/post-editing/Tags'));

type PostType = 'ARTICLE' | 'PAGE';

const POST_LIGHT = gql`
  query PostLight($id: ID!) {
    posts(where: { id: $id }) {
      author {
        id
      }
      category {
        id
      }
    }
  }
`;

const INFO = gql`
  query Info($userId: ID!, $categoryId: ID) {
    users(
      where: {
        OR: [
          {
            OR: [
              { roles_INCLUDES: SUPER }
              { roles_INCLUDES: ADMIN }
              { roles_INCLUDES: AUTHOR_ADMIN }
              { roles_INCLUDES: AUTHOR }
            ]
            deletedAt: null
          }
          { id: $userId }
        ]
      }
    ) {
      id
      displayName
      profile {
        slug
        gender
        picture {
          url
        }
      }
    }
    categories(where: { OR: [{ deletedAt: null }, { id: $categoryId }] }) {
      id
      title
      cover {
        url
      }
    }
  }
`;

const POST = gql`
  query Post($slug: String!, $type: PostType!, $id: ID!) {
    posts(
      where: {
        OR: [{ slug: $slug, status: PUBLISHED, type: $type }, { id: $id }]
      }
    ) {
      id
      revisionsConnection(where: { edge: { status: CURRENT } }) {
        edges {
          node {
            title
            lead
            body
            publishedAt
            author {
              id
              displayName
              profile {
                slug
                gender
                picture {
                  url
                }
              }
            }
            category {
              id
              slug
              title
              cover {
                url
              }
            }
            cover {
              url
            }
            tags {
              id
              slug
              title
            }
          }
        }
      }
    }
  }
`;

export const getFullPost = cache(
  async (parameters: Params, type: PostType = 'ARTICLE') => {
    'use server';

    if (!parameters.slug && !parameters.id) {
      throw new Error('Post not found (empty slug)');
    }

    const result = await client.query({
      query: POST,
      variables: {
        slug: parameters.slug || '',
        id: parameters.id || '',
        type,
      },
    });
    const post = result.data.posts[0];

    if (!post) {
      throw new Error('Post not found (id/slug mismatch)');
    }

    const revision = post.revisionsConnection.edges[0]?.node;

    if (!revision && parameters.id) {
      return {};
    }

    const publishedAt = new Date(revision.publishedAt);

    if (!parameters.id && type === 'ARTICLE') {
      const year = Number.parseInt(parameters.year, 10);
      const month = Number.parseInt(parameters.month, 10);

      if (
        revision.category.slug !== parameters.category ||
        publishedAt.getFullYear() !== year ||
        publishedAt.getMonth() + 1 !== month
      ) {
        throw new Error('Post not found (parameters mismatch)');
      }
    }

    return {
      title: renderFragment(JSON.parse(revision.title), true),
      lead: renderFragment(JSON.parse(revision.lead), true),
      body: renderFragment(JSON.parse(revision.body)),
      publishedAt,
      author: revision.author,
      category: revision.category,
      cover: revision.cover,
      tags: revision.tags,
    };
  },
  'post',
);

interface PostProps {
  readonly editMode?: boolean;
  readonly type?: PostType;
}

export default function Post(originalProps: PostProps) {
  const props = mergeProps({ type: 'ARTICLE' as const }, originalProps);
  const [cookie] = import.meta.env.SSR
    ? [() => null]
    : useCookie(AUTH_TOKEN_COOKIE);

  onMount(() => {
    if (props.editMode && !cookie()) {
      throw new Error('Post not found (unauthorized)');
    }
  });

  const [provider, setProvider] = createSignal<Provider | null>(null);
  const [userList, setUserList] = createSignal<any[]>([]);
  const [categoryList, setCategoryList] = createSignal<any[]>([]);

  createEffect(() => {
    async function resolveProvider() {
      const {
        data: {
          posts: [post],
        },
      } = await client.query({
        query: POST_LIGHT,
        variables: { id: parameters.id },
      });

      if (!post) {
        throw new Error('Post not found (unable to fetch)');
      }

      const {
        data: { users, categories },
      } = await client.query({
        query: INFO,
        variables: {
          userId: post.author.id,
          categoryId: post.category?.id,
        },
      });

      // TODO: !!!PROPERLY VALIDATE COOKIE!!!
      const [, encodedCookie = '{}'] = cookie()?.split('.') ?? [];
      const { sub } = JSON.parse(atob(encodedCookie));

      const thisUser = users.find((user: any) => user.id === sub) ?? {};
      const { buildProvider } = await import(
        '~/components/post-editing/build-collab'
      );

      batch(() => {
        setUserList(users);
        setCategoryList(categories);
        setProvider(provider => {
          provider?.original.destroy();
          return buildProvider(parameters.id, {
            name: thisUser.displayName,
            id: thisUser.id,
            avatar: thisUser.profile.picture.url,
          });
        });
      });

      untrack(provider)
        ?.original.on('stateless', handleStateless)
        .on('connect', handleConnect)
        .on('disconnect', handleDisconnect)
        .on('synced', handleSynced);
    }

    if (!props.editMode || !parameters.id) {
      return;
    }

    if (cookie()) {
      void resolveProvider();
    }

    onCleanup(() => {
      untrack(provider)
        ?.original.off('stateless', handleStateless)
        .off('connect', handleConnect)
        .off('disconnect', handleDisconnect)
        .off('synced', handleSynced);
    });
  });

  const parameters = useParams();
  const post = createAsync(() => {
    if (!parameters.slug && !parameters.id) {
      return Promise.reject(new Error('Post not found (slug not provided)'));
    }

    return getFullPost({ ...parameters }, props.type);
  });

  let isConnected = false;
  const [isSaving, setIsSaving] = createSignal(true);
  const [saveMessage, setSaveMessage] = createSignal('');
  const [isSynced, setIsSynced] = createSignal(false);

  const handleStateless = ({ payload }: onStatelessParameters) => {
    if (!provider()) {
      return;
    }

    const json = JSON.parse(payload);

    switch (json.type) {
      case 'updatingPost':
      case 'sheddingRevision':
      case 'discardingRevision': {
        setIsSaving(true);
        break;
      }

      case 'postUpdated': {
        setSaveMessage('Alterações salvas automaticamente');
        setIsSaving(false);
        break;
      }

      case 'revisionShed': {
        setSaveMessage('Alterações salvas');
        setIsSaving(false);
        break;
      }

      case 'revisionDiscarded': {
        setSaveMessage('Modificações revertidas');
        setIsSaving(false);
        break;
      }

      case 'updateError':
      case 'shedError':
      case 'discardError': {
        setIsSaving(false);
        break;
      }

      default: // Do nothing
    }
  };

  const handleChange = () => {
    if (!provider() || !isSynced()) {
      return;
    }

    provider()?.original.sendStateless(JSON.stringify({ type: 'type' }));
  };

  const handleConnect = () => {
    if (!provider()) {
      return;
    }

    isConnected = true;
  };

  const handleDisconnect = () => {
    if (!provider()) {
      return;
    }

    isConnected = false;
    batch(() => {
      setIsSaving(true);
      setIsSynced(false);
    });
  };

  const handleSynced = () => {
    if (!provider() || !isConnected) {
      return;
    }

    batch(() => {
      setIsSaving(false);
      setIsSynced(true);
    });
  };

  const shedRevision = () => {
    provider()?.original.sendStateless(
      JSON.stringify({ type: 'shed', detail: {} }),
    );
  };

  const shedAndPublish = () => {
    provider()?.original.sendStateless(
      JSON.stringify({ type: 'shed', detail: { status: 'PUBLISHED' } }),
    );
  };

  const discardRevision = () => {
    provider()?.original.sendStateless(JSON.stringify({ type: 'discard' }));
  };

  const trashPost = () => {
    console.log(parameters);
    alert('Em breve...');
  };

  return (
    <ErrorBoundary
      fallback={error => {
        console.error(error);
        return (
          <Show
            when={error.message.startsWith('Post not found')}
            fallback={
              <article>
                <h1>Ouch! Aconteceu um erro ao carregar a página</h1>
                <div class={styles.body}>
                  <p>
                    Mas não se preocupe, ele já está sendo analisado. Ou não. Se
                    ele persistir, mande uma mensagem no nosso{' '}
                    <i>
                      <a href="/chat">chat</a>
                    </i>{' '}
                    o quanto antes!
                  </p>
                </div>
              </article>
            }
          >
            <NotFound />
          </Show>
        );
      }}
    >
      <Title>
        {(props.editMode
          ? `Editando ${props.type === 'ARTICLE' ? 'artigo' : 'página'}: `
          : '') + (fragmentToJsx(post()?.title, true) as string)}
      </Title>
      <main>
        <Show when={provider() !== null}>
          {isSaving()
            ? isSynced()
              ? 'Salvando...'
              : 'Sincronizando...'
            : saveMessage()}
          &nbsp;
          <Show when={props.type === 'ARTICLE'}>
            <p style={{ display: 'flex' }}>
              <Checkbox
                collaborationProvider={provider()?.original}
                collaborationField="sticky"
                onChange={handleChange}
              />
              <label for="sticky">Fixar no topo</label>
            </p>
          </Show>
          <p>
            <button type="button" disabled={isSaving()} onClick={shedRevision}>
              Salvar Revisão
            </button>
            <button
              type="button"
              disabled={isSaving()}
              onClick={shedAndPublish}
            >
              Salvar e Publicar
            </button>
            <button
              type="button"
              disabled={isSaving()}
              onClick={discardRevision}
            >
              Descartar Revisão
            </button>
            <button type="button" disabled={isSaving()} onClick={trashPost}>
              Apagar Artigo
            </button>
          </p>
        </Show>
        <article>
          <h1>
            <Show
              when={provider() !== null}
              fallback={fragmentToJsx(post()?.title)}
            >
              <Editor
                inline
                collaborationProvider={provider()}
                collaborationField="title"
                placeholder="Título..."
                onChange={handleChange}
              />
            </Show>
          </h1>
          <p style={{ 'font-weight': 200, 'font-size': '1.5em' }}>
            <Show
              when={provider() !== null}
              fallback={fragmentToJsx(post()?.lead)}
            >
              <Editor
                inline
                collaborationProvider={provider()}
                collaborationField="lead"
                placeholder="Subtítulo..."
                onChange={handleChange}
              />
            </Show>
          </p>
          <Show when={props.type === 'ARTICLE'}>
            <div>
              <Show
                when={provider() !== null}
                fallback={post()?.author?.displayName}
              >
                <label for="author">Autor:</label>
                <Select
                  collaborationProvider={provider()?.original}
                  collaborationField="author"
                  onChange={handleChange}
                >
                  <For each={userList()}>
                    {user => (
                      <option value={user.id}>{user.displayName}</option>
                    )}
                  </For>
                </Select>
              </Show>
            </div>
            <div>
              <Show
                when={provider() !== null}
                fallback={post()?.category?.title}
              >
                <label for="category">Coluna:</label>
                <Select
                  collaborationProvider={provider()?.original}
                  collaborationField="category"
                  onChange={handleChange}
                >
                  <option disabled value="">
                    - Escolha -
                  </option>
                  <For each={categoryList()}>
                    {category => (
                      <option value={category.id}>{category.title}</option>
                    )}
                  </For>
                </Select>
              </Show>
            </div>
            <div>
              <Show
                when={provider() !== null}
                fallback={post()?.publishedAt?.toLocaleString(
                  import.meta.env.VITE_LOCALE,
                  { timeZone: import.meta.env.VITE_TZ },
                )}
              >
                <label for="publishedAt">Data de publicação:</label>
                <DatePicker
                  collaborationProvider={provider()?.original}
                  collaborationField="publishedAt"
                  onChange={handleChange}
                />
              </Show>
            </div>
          </Show>
          <div>
            <Show when={provider() !== null} fallback={post()?.cover?.url}>
              <label for="cover">Imagem de capa:</label>
              <Image
                collaborationProvider={provider()?.original}
                collaborationField="cover"
                onChange={handleChange}
              />
            </Show>
          </div>
          <div class={styles.body}>
            <Show
              when={provider() !== null}
              fallback={fragmentToJsx(post()?.body)}
            >
              <Editor
                withLinks
                collaborationProvider={provider()}
                collaborationField="body"
                placeholder="Conteúdo..."
                onChange={handleChange}
              />
            </Show>
          </div>
          <Show when={props.type === 'ARTICLE'}>
            <Show
              when={provider() !== null}
              fallback={
                <Show when={(post()?.tags ?? []).length > 0}>
                  <div class={styles['tags-list']}>
                    <ul>
                      <For each={post()?.tags ?? []}>
                        {tag => (
                          <li>
                            <A href={`/tag/${tag.slug as string}`}>
                              <span class={styles.hashtag}>#</span>
                              {tag.title}
                            </A>
                          </li>
                        )}
                      </For>
                    </ul>
                  </div>
                </Show>
              }
            >
              <Tags
                collaborationProvider={provider()?.original}
                collaborationField="tags"
                onChange={handleChange}
              />
            </Show>
          </Show>
        </article>
      </main>
    </ErrorBoundary>
  );
}
